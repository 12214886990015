<template>

    <div>
            
        <div class="contact">
            <p>
                <a :href="contactTelHref" class="btn btn-tel">
                    <!-- <PhoneIcon /> -->
                    {{ contactTel }}
                </a>
            </p>
            <p>
                <a :href="contactEmailHref" class="btn btn-email">
                    <span id="typedMessage">{{ typed }}</span>{{ contactEmail }}
                </a>
            </p>

            <div id="element"></div>
        </div>

    </div>

</template>


<script>
import Typed from 'typed.js';
// import PhoneIcon from './PhoneIcon.vue';

export default {
    data() {
        return {
            contactTel: '06 ' + '5' + '2 50 8' + '3 ' + '32',
            contactTelHref: 'te' + 'l:+3' + '365' + '25' + '0833' + '2',
            contactEmail: '@' + 'lak' + 'averne.' + 'f' + 'r',
            contactEmailHref: 'm' + 'ai' + 'lt' + 'o:hel' + 'lo@' + 'lak' + 'averne.' + 'f' + 'r',
            typed: '',
        }
    },

    // components: {
    //     PhoneIcon,
    // },

    mounted() {
        this.typed = new Typed('#typedMessage', {
            strings: [
                'ola',
                'bonjour',
                'hello',
            ],
            typeSpeed: 150,
            loop: true,
            backSpeed: 80,
            backDelay: 1500,
        })
    },


}



</script>


<style>

.contact {
    font-size: 2rem;

	a {
		color: var(--primary-color-medium2);
	}
}

</style>