<template>

<section class="portfolio">

    <h2>Collaborations<!-- <br>
        <span class="soustitre">Hébergement, développement, mails, ou un peu tout ça.</span> -->
    </h2>

    <section>

        <PortfolioCard
            v-for="(collab, index) in collabs"
            :key="index"
            :collab="collab"
            :imgDirectory="imgDirectory"
        />


    </section>

</section>


</template>


<script>
import PortfolioCard from './PortfolioCard.vue';


export default {
    data() {
        return {
            imgDirectory: "img/collabs/",
            collabs: [
                {
                    nom: "A2F COM",
                    desc: "Graphiste à Pont-sur-Yonne (89)",
                    url: "https://a2f-com.fr",
                    img: "a2f-com.fr_150x150.jpg",
                    imgSrc: "",
                    imgAlt: "A2F Com, graphiste à Pont-sur-Yonne (89)",
                },
                {
                    nom: "ABI Graphique",
                    desc: "Automatisation et mécanisation à St Fargeau Ponthierry (77)",
                    url: "https://abigraphique.com/",
                    img: "abigraphique.com_150x150.jpg",
                    imgSrc: "",
                    imgAlt: "Solutions d'automatisation et de mécanisation",
                },
                {
                    nom: "AMF 66",
                    desc: "Association des Maires<br>des Pyrénées Orientales (66)",
                    url: "https://amf66.fr",
                    img: "amf66.fr_150x150.jpg",
                    imgSrc: "",
                    imgAlt: "Association des Maires des Pyrénées Orientales",
                },
                {
                    nom: "Association In'Pact",
                    desc: "Insertion socio-professionnelle à Vernou-la-Celle (77)",
                    url: "https://association-inpact.fr/",
                    img: "associationInpact_150x150.jpg",
                    imgSrc: "",
                    imgAlt: "In'Pact, Insertion socio-professionnelle à Vernou-la-Celle",
                },
                {
                    nom: "Domaine équestre d'Ilohé",
                    desc: "Élevage de chevaux, pension, formation<br>et compétition à Saint-Urcisse (47)",
                    url: "https://dedilohe.com",
                    img: "dedilohe.com_150x150.jpg",
                    imgSrc: "",
                    imgAlt: "Domaine équestre",
                },
                {
                    nom: "Fontainebleau Atelier 29",
                    desc: "École de musique à Fontainebleau (77)",
                    url: "https://www.fontainebleau-atelier29.fr",
                    img: "fontainebleau-atelier29.fr_150x150.jpg",
                    imgSrc: "",
                    imgAlt: "Fontainebleau Atelier 29, École de musique à Fontainebleau (77)",
                },
                {
                    nom: "Identity",
                    desc: "Conseil en communication<br>à Fontainebleau et Perpignan (66)",
                    url: "https://i-dentity.fr",
                    img: "i-dentity.fr_150x150.jpg",
                    imgSrc: "",
                    imgAlt: "Conseil en communication à Fontainebleau et Perpignan",
                },
                {
                    nom: "La Terrasse FMR",
                    desc: "Terrasse, restaurant, club à Bondoufle (91)",
                    url: "https://laterrassefmr.fr",
                    img: "laTerrasseFMR.fr_150x150.jpg",
                    imgSrc: "",
                    imgAlt: "Terrasse, restaurant, club à Bondoufle",
                },
                {
                    nom: "La Tribune Des Métiers",
                    desc: "Magazine des professionnels<br>de la Boulangerie Pâtisserie à Quimper (29)",
                    url: "https://www.latribunedesmetiers.fr",
                    img: "latribunedesmetiers.fr_150x150.jpg",
                    imgSrc: "",
                    imgAlt: "Portail du magazine des professionnels des métiers de bouche et de la boulangerie pâtisserie",
                },
                {
                    nom: "Lav'inox",
                    desc: "Matériel et hygiène pour<br>les professionnels<br>des métiers de bouche à Moëlan-sur-Mer (29)",
                    url: "https://lavinox.fr",
                    img: "lavinox.fr_150x150.jpg",
                    imgSrc: "",
                    imgAlt: "Site web de Lav'Inox, équipementier importateur pour les professionnels de la restauration, restauration collective, boulangers pâtissiers, ...",
                },
                {
                    nom: "Maison Laury",
                    desc: "Produits de saison,<br>fruits et légumes à Paris (75)",
                    url: "https://maison-laury.fr",
                    img: "maison-laury.fr_150x150.jpg",
                    imgSrc: "",
                    imgAlt: "Maison Laury, produits de saison, fruits et légumes",
                },
                {
                    nom: "Marquise et Tradition",
                    desc: "Chocolaterie à Saint-Michel-sur-Orge (91)",
                    url: "https://marquise-et-tradition.com",
                    img: "marquise-et-tradition.com_150x150.jpg",
                    imgSrc: "",
                    imgAlt: "Marquise et Tradition, Chocolaterie à Saint-Michel-sur-Orge (91)",
                },
                {
                    nom: "Planète Fruits",
                    desc: "Fruits et produits frais à Paris (75)",
                    url: "https://planetefruits.com",
                    img: "planetefruits.com_150x150.jpg",
                    imgSrc: "",
                    imgAlt: "Planète Fruits, Fruits et produits frais à Paris",
                },
                {
                    nom: "Sens Travel",
                    desc: "Location de vans et<br>de camping-cars à Sens (89)",
                    url: "https://sens-travel.com",
                    img: "sens-travel.com_150x150.jpg",
                    imgSrc: "",
                    imgAlt: "Sens Travel, location de vans et camping-cars.",
                },
                {
                    nom: "Solidarité Emploi Yonne Nord",
                    desc: "Insertion par l'activité économique<br>à Pont-sur-Yonne (89)",
                    url: "",
                    img: "solidariteemploiyn.fr_150x150.jpg",
                    imgSrc: "",
                    imgAlt: "Solidarité Emploi Yonne Nord",
                },                {
                    nom: "Van Den Immobilier",
                    desc: "Agence immobilière à Fontainebleau (77)",
                    url: "https://vandenimmobilier.fr",
                    img: "vandenimmobilier.fr_150x150.jpg",
                    imgSrc: "",
                    imgAlt: "Site web de l'agence immobilière Van Den Immobilier à Fontainebleau",
                },
                {
                    nom: "Vert Équip.fr",
                    desc: "Aménagement intérieur et extérieur,<br>piscines, spas à Montarlot (77)",
                    url: "https://vert-equip.fr",
                    img: "vert-equip.fr_150x150.jpg",
                    imgSrc: "",
                    imgAlt: "Site web de Vert Équip' à Bourron-Marlotte et Montarlot, aménagement et mobilier extérieur, piscines, spas, ...",
                },
                {
                    nom: "Et vous ?",
                    desc: "Quel est votre projet ?",
                    url: "#",
                    img: '',
                    imgSrc: "https://picsum.photos/150/150?grayscale",
                    imgAlt: "Votre futur site web",
                },
            ]
        }
    },
    components: {
        PortfolioCard,
    },
}

</script>


<style scoped>

.portfolio {

    h2 {
        font-family: Dune_Rise;
        font-size: 1.6rem;
        margin: 50px auto;
        color: var(--primary-color-medium2);
    }

    section {

        display: flex;
        flex-wrap: wrap;
        gap: 20px;

    }

}


</style>