<template>
    
    <span class="services services-list"><strong>Sites Web</strong>,
        <br>Services Web et Multimédia
        <br>Mails, Hébergement
        <br>Logiciels libres</span>
    <br><span class="services services-infos">depuis Voulx en Seine-et-Marne (77)</span>

</template>

<style scoped>

.services-list {
    font-weight: 600;
}

.services-infos {
    font-size: 75%;
}

</style>