<template>
  <IndexPage />
</template>

<script>
import IndexPage from './components/IndexPage.vue'


export default {
  name: 'App',
  components: {
    IndexPage,
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  text-align: center;
	color: var(--primary-text);
	font-family: "Open Sans", "Arial, Helvetica Neue", sans-serif;
	font-size: 16px;
}

@import url('assets/css/styleGlobal.css')
</style>
