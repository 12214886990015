<template>

    <a :href="collab.url" target="_blank" rel="external" nofollow>

        <img
            v-if="collab.img !== ''"
            :src="imgDirectory + collab.img"
            :alt="collab.imgAlt"
        />

        <img
            v-else-if="collab.imgSrc !== ''" 
            :src="collab.imgSrc" 
            :alt="collab.imgAlt" 
        />

        <div v-else class="noImg"></div>

        <legend>
            <h3 v-html="collab.nom"></h3>
            <h4 v-html="collab.desc"></h4>
        </legend>
    </a>

</template>


<script>

export default {
    props: {
        collab: {
            required: true,
            type: Object,
        },
        imgDirectory: {
            type: String,
            default: '',
        },
    },
}

</script>


<style scoped>

a {
    background: var(--primary-color-light1);
    flex-grow: 1;
    padding: 20px;
    color: var(--primary-color-medium2);
    text-decoration: none;
    border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        background-clip: padding-box;

    img,
    .noImg {
        border-radius: 200px;
            -webkit-border-radius: 200px;
            -moz-border-radius: 200px;
            -ms-border-radius: 200px;
            -o-border-radius: 200px;
            background-clip: padding-box;
        transform: rotate(0);
        transition: transform var(--transition-duration) ease;
        box-shadow: 0px 5px 25px rgba(0,0,0,0.2), 0px 0 3px rgba(0,0,0,0.4);
        margin: 2ch auto;
    }

    img:hover {
        transform: rotate(10deg);
        transition: transform var(--transition-duration) ease;
    }
}

.noImg {
    background-color: var(--primary-color-medium2);
    height: 150px;
    width: 150px;
    max-height: 150px;
    max-width: 150px;
    opacity: 0.5;
}

h3 {
    margin: 10px auto 5px;
}

h4 {
    font-weight: normal;
    margin: 0;
}

</style>