<template>

<div class="container">

    <header>

        <section class="identite">

            <h1>
                <LogoLk />
            </h1>

            <h2>
                <ServicesList />
            </h2>

            <ContactPage />

        </section>
        
    </header>

    <PortfolioList />

    <footer>
        <h2>La Kaverne</h2>
        <h3>Création de sites internet et<br>
            services informatiques à Voulx<br>
            77 - Seine-et-Marne</h3>
    </footer>

</div>

</template>


<script>

import LogoLk from './LogoLk.vue'
import ServicesList from './ServicesList.vue'
import ContactPage from './ContactPage.vue'
import PortfolioList from './PortfolioList.vue'

export default {

    components: {
        LogoLk,
        ServicesList,
        ContactPage,
        PortfolioList,
    },

}

</script>


<style scoped>

.container {
    /* max-width: 900px; */
    /* margin: 10px auto; */
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    section {
		container: page-section / inline-size;
	}
}

header,
footer {
	/* background-color: var(--primary-color-light1); */
	border-radius: 15px;
		-webkit-border-radius: 15px;
		-moz-border-radius: 15px;
		-ms-border-radius: 15px;
		-o-border-radius: 15px;
		background-clip: padding-box;
	padding: 40px;
}

header {

    h1 {
        margin: 0;
    }

    h2 {
        font-weight: normal;
    }

}


footer {
    color: var(--primary-color-medium1);

	h2 {
		margin-bottom: 0;
	}

	h3 {
		font-weight: normal;
		margin-top: 0;
	}

}


/* @container page-section (max-width: 360px) {

    header, footer {
        border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
            background-clip: padding-box;
        padding: 10px;
    }

} */

</style>