import { createApp } from 'vue'
import App from './App.vue'

// import VueTypedJs from 'vue-typed-js'

const LkApp = createApp(App)

LkApp
// .use(VueTypedJs)
.mount('#app')


