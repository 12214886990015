<template>

<img src="img/logo-lakaverne-omega.png" width="537" height="349" alt="Logo la Kaverne" title="LaKaverne.fr" />

</template>


<style scoped>

img {
    max-width: 100%;
    height: auto;
}

</style>